import styled from 'styled-components';
import SoughtAmountCell from './TableCells/SoughtAmountCell';
import RateCell from './TableCells/RateCell';

export const caseSummaryForm = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'Community(ies)',
        name: 'community',
        placeholder: 'Select community(ies)',
        width: '320px',
        isCheckboxSelect: true,
        isMandatory: true,
      },
    ],
  },
];

const StyledHeader = styled.div`
  flex-grow: 1;
  text-align: right;
  font-size: 16px;
`;

export const productivityByAttorneyColumns = [
  {
    Header: 'Reference #',
    accessor: 'caseReferenceNumber',
    width: 15,
  },
  {
    Header: 'Opposing Party',
    accessor: 'opposingParty',
    width: 20,
  },
  {
    Header: 'Address',
    accessor: 'address',
    width: 15,
  },
  {
    Header: 'Apartment #',
    accessor: 'apartmentNumber',
    width: 10,
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
    width: 10,
  },
  {
    Header: 'Requested On',
    accessor: 'requestedOn',
    width: 10,
    Cell: RateCell,
  },
  {
    Header: () => <StyledHeader>Proposed Amount Sought</StyledHeader>,
    accessor: 'proposedAmountSought',
    width: 20,
    Cell: SoughtAmountCell,
  },
];
