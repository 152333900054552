import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dropdown from '../../atoms/Dropdown';
import EmptyBlock from '../../molecules/EmptyBlock';
import Page from '../../organisms/Page/Page';
import { reportsConfig } from './Reports.constants';
import Tile from '../../molecules/Tile';

const Reports = ({ base }) => {
  const location = useLocation();
  const { navPage = reportsConfig.name, subNav = '' } = location?.state ?? {};
  const navigate = useNavigate();
  const [report, setReport] = useState(null);

  useEffect(() => {
    if (report)
      navigate(`/${base}/reports/${report.value}`, {
        state: {
          navPage,
          subNav: report.label,
        },
      });
  }, [report]);

  useEffect(() => {
    if (subNav && !report) {
      const option = reportsConfig.dropdownOptions[base].find((o) => o.label === subNav);
      if (option) setReport(option);
    }
    if (!subNav && report) setReport(null);
  }, [subNav]);

  const reportsExist = Boolean(reportsConfig.dropdownOptions[base]?.length);

  return (
    <Page navPage={navPage} subNavigation={subNav}>
      <Stack direction="column" height={reportsExist ? 'fit-content' : '100%'}>
        <Typography variant="h3" margin="0 32px">
          Reports
        </Typography>
        {!report ? (
          <Box
            display="flex"
            height="100%"
            mt={8}
            mb={25}
            gap={5.5}
            flexWrap="wrap"
            mx={4}
            justifyContent={reportsExist ? 'flex-start' : 'center'}
            alignItems={reportsExist ? 'flex-start' : 'center'}
          >
            {reportsExist ? (
              reportsConfig.dropdownOptions[base].map((r) => (
                <Tile key={r.value} {...r} ctaAction={() => setReport(r)} ctaText="Run Report" />
              ))
            ) : (
              <EmptyBlock title={reportsConfig.emptyLabel} descFontSize={18} />
            )}
          </Box>
        ) : (
          <Stack paddingX={4}>
            <Box width={440} margin="32px 0">
              <Dropdown
                options={reportsConfig.dropdownOptions[base]}
                placeholder={reportsConfig.dropdownPlaceholder}
                label={reportsConfig.dropdownLabel}
                value={report}
                isColumn
                onChange={setReport}
              />
            </Box>
            <Outlet />
          </Stack>
        )}
      </Stack>
    </Page>
  );
};

Reports.propTypes = {
  base: PropTypes.string.isRequired,
};

export default Reports;
