import { getDashboardBarConfig, rolesCP, rolesDB } from '../../../utils/roleHelpers';
import { defaultOptionPage } from '../../molecules/LoginWidget/LoginWidget.constants';

const { Admin, Attorney, SchedulingAssistant, Accounting } = rolesDB;
const { ClientPortalAdmin } = rolesCP;

export const getDatabaseNavbar = () => [
  getDashboardBarConfig(),
  {
    label: 'Processing',
    dropdownWidth: '210px',
    pathname: 'processing',
    dropdownNavigationConfig: [
      {
        label: 'Case Search',
        to: '/db/processing/casesearch',
        state: {
          preserveFilters: false,
          navPage: 'Processing',
          subNav: 'Cases',
        },
      },
      {
        label: 'Notices',
        to: '/db/processing/notices',
      },
      {
        label: 'Bulk Document Handling',
        to: '/db/processing/bulkdocumentprinting',
      },
    ],
  },
  {
    label: 'Schedule',
    to: '/db/schedule',
    pathname: 'schedule',
    dropdownWidth: '260px',
    whitelist: [SchedulingAssistant, Attorney, Admin],
    dropdownNavigationConfig: [
      {
        label: 'Proposed Form of Judgements',
        to: '/db/schedule/judgements',
      },
      {
        label: 'Attorney Assignments',
        to: '/db/schedule/attorneyassignments',
      },
      {
        label: 'Add Court Dates',
        to: '/db/schedule/addcourtdates',
      },
    ],
  },
  {
    label: 'Accounting',
    to: '/db/accounting/communities',
    dropdownWidth: '170px',
    pathname: 'accounting',
    whitelist: [Accounting, Admin],
    dropdownNavigationConfig: [
      {
        label: 'Invoice Generation',
        to: '/db/accounting/invoicegeneration',
      },
      {
        label: 'Print Checks',
        to: '/db/accounting/printchecks',
      },
      {
        label: 'Billing Instances',
        to: '/db/accounting/billingInstances',
        whitelist: [Admin],
      },
    ],
  },
  {
    label: 'Reports',
    pathname: 'reports',
    to: '/db/reports',
    whitelist: [Attorney, Admin],
  },
  {
    label: 'Data Handling',
    pathname: 'datahandling',
    to: '/db/datahandling',
    dropdownWidth: '238px',
    dropdownNavigationConfig: [
      {
        label: 'Managements',
        to: '/db/datahandling/managements',
      },
      {
        label: 'Owner Entities',
        to: '/db/datahandling/ownerentities',
      },
      {
        label: 'Communities',
        to: '/db/datahandling/communities',
      },
      {
        label: 'Addresses',
        to: '/db/datahandling/addresses',
      },
      {
        label: 'Courts',
        to: '/db/datahandling/courts',
      },
      {
        label: 'Contacts',
        to: '/db/datahandling/contacts',
      },
    ],
  },
  {
    label: 'Admin Panel',
    to: '/db/admin',
    pathname: 'admin',
    whitelist: [Admin],
    dropdownWidth: '238px',
    dropdownNavigationConfig: [
      {
        label: 'Users',
        to: '/db/admin/users',
      },
      {
        label: 'Roles',
        to: '/db/admin/roles',
      },
      {
        label: 'Documents',
        to: '/db/admin/documents',
      },
      {
        label: 'Client Request Notifications',
        to: '/db/admin/clientrequestnotifications',
      },
      {
        label: 'Log-in Page',
        to: '/db/admin/login',
      },
    ],
  },
];

export const getClientPortalNavbar = (base, excluded) => {
  const initialArray = [
    { label: 'Dashboard', pathname: 'dashboard', to: `/${base}/dashboard` },
    {
      label: 'Cases',
      pathname: 'cases',
      to: `/${base}/cases/casesearch`,
      state: {
        preserveFilters: false,
        navPage: 'Cases',
      },
    },
    {
      label: 'Requests',
      pathname: 'requests',
      to: `/${base}/requests`,
    },
    {
      label: 'Legal Forms',
      pathname: 'legalgforms',
      to: `/${base}/legalforms`,
    },
    { label: 'Fee Schedule', pathname: 'feeschedule', to: `/${base}/feeschedule` },
    {
      label: 'Invoices',
      pathname: 'invoices',
      to: `/${base}/invoices`,
    },
    {
      label: 'Reports',
      pathname: 'reports',
      to: `/${base}/reports`,
      state: {
        preserveFilters: false,
        navPage: 'Reports',
      },
    },
    {
      label: 'Admin',
      pathname: 'admin',
      to: `/${base}/admin`,
      whitelist: [Admin, ClientPortalAdmin],
      dropdownNavigationConfig: [
        {
          label: 'Users',
          to: `/${base}/admin/users`,
        },
      ],
    },
  ];

  if (excluded?.length) {
    const pages = initialArray.filter((item) => !excluded.includes(item.label));
    return pages;
  }
  return initialArray;
};

export const getLandingPagesByRoles = (roles, isCP = 'false', base = 'cp', excluded = []) => {
  // const roles = ["Attorney"];
  const listOfUrls = isCP ? getClientPortalNavbar(base, excluded) : getDatabaseNavbar();

  const filteredListOfUrlsByRoles = listOfUrls.reduce((acc, page) => {
    const listForSubpages = () => {
      page.dropdownNavigationConfig?.forEach((subPage) => {
        if (subPage.whitelist) {
          const isSubPageIncludedInTheList = !!subPage.whitelist.find((item) => {
            return roles.includes(item);
          });
          if (isSubPageIncludedInTheList) {
            acc.push({
              label: subPage.label,
              href: subPage.to,
              value: subPage.label,
            });
          }
        } else {
          acc.push({
            label: subPage.label,
            href: subPage.to,
            value: subPage.label,
          });
        }
        return acc;
      });
    };

    if (page.whitelist) {
      const isPageIncludedInTheList = !!page.whitelist.find((item) => {
        return roles.includes(item);
      });

      if (!page.dropdownNavigationConfig && isPageIncludedInTheList) {
        acc.push({
          label: page.label,
          href: page.to,
          value: page.label,
        });
        return acc;
      }
      if (isPageIncludedInTheList) {
        listForSubpages();
      } else {
        return acc;
      }
    } else {
      if (!page.dropdownNavigationConfig) {
        acc.push({
          label: page.label,
          href: page.to,
          value: page.label,
        });
      }
      listForSubpages();
    }

    return acc;
  }, []);

  return filteredListOfUrlsByRoles.length ? filteredListOfUrlsByRoles : [defaultOptionPage];
};
