import React from 'react';
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const NameCell = ({ value }) => (
  <Stack data-testid="cell_wrapper" direction="row" alignItems="center">
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: '16px',
      }}
      data-testid="name_cell"
    >
      {value}
    </Typography>
  </Stack>
);

NameCell.propTypes = {
  value: PropTypes.string,
};

NameCell.defaultProps = {
  value: '',
};

export default NameCell;
