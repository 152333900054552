export const stateCodesOptions = [
  { value: 'AZ', label: 'AZ' },
  { value: 'NV', label: 'NV' },
];

export const stateNames = {
  AZ: 'Arizona',
  NV: 'Nevada',
};

export const CourtReportFormBoxes = [
  {
    name: 'dataFields',
    inputBoxes: [
      {
        label: 'State',
        name: 'stateCode',
        placeholder: '-',
        width: '226px',
        isDropdown: true,
        isMandatory: true,
      },
    ],
  },
  {
    name: 'periods',
    inputBoxes: [
      {
        label: 'From Date',
        name: 'fromDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
      {
        label: 'To Date',
        name: 'toDate',
        width: '226px',
        placeholder: 'mm/dd/yyyy',
        isMandatory: true,
        isDatePicker: true,
        height: '48px',
      },
    ],
  },
];

export const CourtReportColumns = [
  {
    Header: 'Community Name',
    accessor: 'communityName',
  },
  {
    Header: 'Opposing Party',
    accessor: 'opposingParty',
  },
  {
    Header: 'Case #',
    accessor: 'caseNumber',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
