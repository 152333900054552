export const typography = {
  fontFamily: ['Plus Jakarta Sans', 'sans-serif'].join(','),
  fontStyle: 'normal',
  fontSize: 14,
  letterSpacing: '-0.02em',
  h1: {
    fontWeight: 500,
    fontSize: 56,
    lineHeight: '64px',
  },
  h2: {
    fontWeight: 500,
    fontSize: 48,
    lineHeight: '56px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 40,
    lineHeight: '48px',
  },
  h4: {
    fontWeight: 500,
    fontSize: 32,
    lineHeight: '40px',
  },
  h5: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '32px',
  },
  bodyL: {
    fontSize: 18,
    lineHeight: '26px',
  },
  bodyL500: {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '26px',
  },
  bodyL600: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '26px',
  },
  bodyM: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
  },
  bodyM400: {
    fontSize: 16,
    fontWeight: '400 !important',
    lineHeight: '26px',
    letterSpacing: '-0.32px',
  },
  bodyS: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.5,
  },
  bodySThin: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.5,
  },
  bodyXS: {
    fontSize: 12,
    lineHeight: '15px',
  },
  link: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 1.5,
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
  },
  buttonLarge: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: 1.5,
  },
  buttonSmall: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
    textTransform: 'none',
  },
};
