import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Stack, Typography, Box, LinearProgress } from '@mui/material';
import moment from 'moment';
import ShadowBlock from '../../../atoms/ShadowBlock';
import Form from '../../../atoms/Form';
import DateOrTimePicker from '../../../atoms/DateOrTimePicker';
import MultipleSelectChip from '../../../atoms/MultipleSelectChip';
import CheckboxWithLabel from '../../../molecules/CheckboxWithLabel';
import {
  ALL_ATTORNEYS,
  ATTORNEYS_EXTRA_OPTION,
  ProductivityByAttorneyFormBoxes,
  TYPE_OPTIONS,
  productivityByAttorneyColumns,
} from './ProductivityByAttorney.constants';
import Field from '../../../atoms/Field';
import { ReactComponent as Calender } from '../../../../assets/icons/Calender.svg';
import MButton from '../../../MUI/Button/MButton';
import Table from '../../../atoms/Table/Table';
import { apiClient } from '../../../../lib/apiClient';
import ProgressModal from './ProgressModal';
import CheckboxSelect from '../../../atoms/CheckboxSelect';
import { palette } from '../../../../theme/default';
import StateLink from '../../../atoms/StateLink/StateLink';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/document-download-blue.svg';
import MDialog from '../../../atoms/MDialog/MDialog';
import { openOrDownloadBinary } from '../../../../utils/binaryHelpers';
import {
  getProductivityAttorneyCsv,
  getProductivityAttorneyPdf,
} from '../../../../store/slices/reportsSlice';

const ProductivityByAttorney = () => {
  const form = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableLabel, setTableLabel] = useState('');
  const [timer, setTimer] = useState(false);
  const [lastReportId, setLastReportId] = useState(null);
  const [isExportActivitiesDialogOpen, setIsExportActivitiesDialogOpen] = useState(false);
  const [isExportActivitiesDialogLoader, setIsExportActivitiesDialogLoader] = useState(false);

  const dispatch = useDispatch();

  const clearCurrentStates = () => {
    setTableData([]);
    setReportData(null);
    setTotalData(null);
    setIsLoading(false);
  };

  const onExportActivitiesDialogOpen = useCallback(() => {
    setIsExportActivitiesDialogOpen(true);
  }, []);

  const onExportActivitiesDialogClose = useCallback(() => {
    setIsExportActivitiesDialogOpen(false);
  }, []);

  useEffect(() => {
    form.setValue('type', [TYPE_OPTIONS[0].value]);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      clearCurrentStates();
    };
  }, [timer]);

  useEffect(() => {
    const fetcher = async () => {
      const { data } = await apiClient.get('/api/cases/attorneys');
      const list = data.result.map((item) => ({
        id: item.userId,
        value: item.userId,
        label: `${item.firstName} ${item.lastName}`,
      }));
      setUsers([ATTORNEYS_EXTRA_OPTION, ...list]);
    };
    fetcher();
  }, []);

  useEffect(() => {
    if (!reportData) return;
    const [data] = reportData;
    const newTableData = [...data.items, { ...totalData, isTotalRow: true }];
    setTableData(newTableData);
  }, [reportData, totalData]);

  const getProductivityReport = async (id) => {
    const {
      data: { reportStatus, items },
    } = await apiClient.get(`/api/reports/productivityByAttorneyReport/${id}`);
    if (reportStatus?.code === 'Completed') {
      setIsLoading(false);
      setReportData(items);
      setTotalData(items[0]?.totals);
    } else {
      const timerKey = setTimeout(() => {
        getProductivityReport(id);
      }, 3000);
      setTimer(timerKey);
    }
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const { fromDate, toDate, user, type, showDescription } = values;

    let selectedAttorneysId = [];
    const isAllAttorneysSelected = user.some((o) => o.value === ALL_ATTORNEYS);

    if (!isAllAttorneysSelected) {
      selectedAttorneysId = user.map((item) => item.id);
    }

    const reqBody = {
      ...(fromDate && { fromDate: moment(fromDate, moment.ISO_8601) }),
      ...(toDate && { toDate: moment(toDate, moment.ISO_8601) }),
      selectedAttorneysId,
      allAttorneys: isAllAttorneysSelected,
      types: type,
      showDescription,
    };

    const { data } = await apiClient.post(`/api/reports/productivityByAttorneyReport`, reqBody);
    if (data.reportId) {
      setLastReportId(data.reportId);
      getProductivityReport(data.reportId);
    }
    setTableLabel(`${fromDate.format('MM/DD/YY')} - ${toDate.format('MM/DD/YY')}`);
  };

  const getOptions = useCallback((dropdownName) => {
    switch (dropdownName) {
      case 'type':
        return TYPE_OPTIONS;
      default:
        return [];
    }
  }, []);

  const getFilteredColumns = useCallback(() => {
    if (!form.getValues('showDescription')) {
      return productivityByAttorneyColumns.filter((column) => column.accessor !== 'description');
    }
    return productivityByAttorneyColumns;
  }, []);

  const onExportActivities =
    (docType = 'pdf') =>
    async () => {
      const actionsByDocType = {
        csv: getProductivityAttorneyCsv,
        pdf: getProductivityAttorneyPdf,
      };
      setIsExportActivitiesDialogLoader(true);

      try {
        const request = actionsByDocType[docType];
        const data = await dispatch(request(lastReportId)).unwrap();

        openOrDownloadBinary({
          contentType: data.contentType,
          content: data.content,
          fileName: data.originalFileName,
        });
      } catch (e) {
        // todo: Add Alert Component
        alert('Unexpected error during exporting', e);
      }

      setIsExportActivitiesDialogLoader(false);
      setIsExportActivitiesDialogOpen(false);
    };

  const rowsAdapter = useMemo(() => {
    return tableData?.map((item) => {
      return {
        ...item,
        referenceNumber: (
          <StateLink
            to={`/db/processing/case/${item.caseId}`}
            state={{
              navPage: 'Processing',
              subNav: 'Cases',
              state: { value: item.stateCode },
            }}
            alwaysOpenInNewTab
          >
            <Typography
              variant="link"
              color="text.primary"
              sx={{
                cursor: 'pointer',
                textDecorationLine: 'underline',
                textTransform: 'none',
              }}
            >
              {item.referenceNumber}
            </Typography>
          </StateLink>
        ),
      };
    });
  }, [tableData]);

  return (
    <>
      <Stack pb={20}>
        <ProgressModal isOpen={isLoading} />
        <ShadowBlock>
          <Form form={form} onSubmit={handleSubmit}>
            <Stack
              direction="row"
              alignItems="center"
              columnGap={3}
              rowGap={3}
              flexWrap="wrap"
              py={1}
            >
              {ProductivityByAttorneyFormBoxes.map((item) => {
                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <CheckboxSelect
                          value={field.value || []}
                          label={item.label}
                          width={item.width}
                          error={error}
                          isMandatory={item.isMandatory}
                          placeholder={item.placeholder}
                          onChange={onCustomChange(field.onChange)}
                          options={users}
                        />
                      )}
                    />
                  );
                }

                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDropdownMultiChip) {
                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <MultipleSelectChip
                          value={field.value || []}
                          label={item.label}
                          width={item.width}
                          height={item.height}
                          error={error}
                          isMandatory={item.isMandatory}
                          placeholder={item.placeholder}
                          options={getOptions(item.name)}
                          onChange={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isCheckbox) {
                  return (
                    <Field
                      key={item.label}
                      name={item.name}
                      render={({ field, onCustomChange }) => (
                        <CheckboxWithLabel
                          isDisabled={item.isDisabled}
                          key={item.label}
                          label={item.label}
                          isChecked={field.value}
                          onChange={onCustomChange(field.onChange)}
                          marginTop="28px"
                        />
                      )}
                    />
                  );
                }

                return null;
              })}
            </Stack>
            <Stack
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                mt: '36px',
              }}
            >
              <MButton type="submit" disabled={isLoading || !form.formState.isValid}>
                Run Report
              </MButton>
            </Stack>
          </Form>
        </ShadowBlock>

        {Boolean(tableData?.length) && (
          <Stack mt={4}>
            <ShadowBlock>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={4}>
                <Typography variant="h5" fontWeight={600}>
                  Productivity by Attorney
                </Typography>
                <MButton
                  variant="bordered"
                  startIcon={<ExportIcon />}
                  onClick={onExportActivitiesDialogOpen}
                  disabled={tableData.length <= 1}
                >
                  Export PDF
                </MButton>
              </Box>
              <Stack rowGap={2}>
                <Typography variant="bodyXS" color={palette.text.secondary}>
                  {tableLabel}
                </Typography>
                <Table columns={getFilteredColumns()} rows={rowsAdapter} hasTotalRow />
              </Stack>
            </ShadowBlock>
          </Stack>
        )}
      </Stack>
      <MDialog
        isOpen={isExportActivitiesDialogOpen}
        title="Export Activites As"
        onClose={onExportActivitiesDialogClose}
      >
        {isExportActivitiesDialogLoader ? (
          <Box
            sx={{
              width: '208px',
              height: '48px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <LinearProgress />
          </Box>
        ) : (
          <Stack direction="row" justifyContent="space-around" columnGap={2}>
            <MButton size="large" onClick={onExportActivities('pdf')}>
              PDF
            </MButton>
            <MButton size="large" onClick={onExportActivities('csv')}>
              CSV
            </MButton>
          </Stack>
        )}
      </MDialog>
    </>
  );
};

export default ProductivityByAttorney;
