import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Reports from '../../../components/pages/Reports';
import CourtReport from '../../../components/pages/Reports/ClientPortalReports/CourtReport';
import PropertyActivity from '../../../components/pages/Reports/ClientPortalReports/PropertyActivityReport';
import ClientSummaryReport from '../../../components/pages/Reports/ClientPortalReports/CaseSummaryReport';

const ClientPortalReportsRoutes = () => (
  <Routes>
    <Route element={<Reports base="cp" />}>
      <Route path="courtreport" element={<CourtReport />} />
      <Route path="activityreport" element={<PropertyActivity />} />
      <Route path="summaryreport" element={<ClientSummaryReport />} />
      <Route index />
    </Route>
  </Routes>
);

export default ClientPortalReportsRoutes;
