/* eslint-disable no-await-in-loop */
import React, { useState, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  AccordionSummary,
  AccordionDetails,
  styled,
  Container,
  Accordion,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import ShadowBlock from '../../../../atoms/ShadowBlock';
import Form from '../../../../atoms/Form';
import { CourtReportColumns, CourtReportFormBoxes, stateNames } from './CourtReport.constants';
import MButton from '../../../../MUI/Button/MButton';
import Field from '../../../../atoms/Field';
import DateOrTimePicker from '../../../../atoms/DateOrTimePicker';
import { ReactComponent as Calender } from '../../../../../assets/icons/Calender.svg';
import {
  createClientCourtReport,
  getClientCourtReport,
  getClientCourtReportAsPdf,
} from '../../../../../store/slices/reportsSlice';
import timeoutPromise from '../../../../../utils/timeoutPromise';
import Dropdown from '../../../../atoms/Dropdown';
import Table from '../../../../atoms/Table/Table';
import { useAvailableStatesList } from '../../../../../hooks/useAvailableStatesList';
import notificationUtils from '../../../../../utils/notificationUtils';
import { openOrDownloadBinary } from '../../../../../utils/binaryHelpers';
import { ReactComponent as PdfIcon } from '../../../../../assets/icons/document-download-blue.svg';
import { palette } from '../../../../../theme/default';
import EmptyBlock from '../../../../molecules/EmptyBlock';
import { ReactComponent as ArrowDown } from '../../../../../assets/icons/ArrowDown.svg';

const StyledAccordion = styled(Accordion)({
  '&': {
    border: 'none',
    borderRadius: '24px',
  },
});
const StyledContainer = styled(Container)({
  '&': {
    marginTop: '32px',
    '.MuiAccordion-root:first-of-type': {
      borderTopLeftRadius: '16px',
      borderTopRightRadius: '16px',
    },
    '.MuiAccordion-root:last-of-type': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '.MuiPaper-root': {
      marginBottom: '16px',
      borderRadius: '16px',
      boxShadow: '0px 3px 32px rgb(106 114 142 / 10%)',
    },
  },
});

const CourtReport = () => {
  const userStates = useAvailableStatesList();

  const [isLoader, setIsLoader] = useState(false);
  const [lastReportId, setLastReportId] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [currentState, setCurrentState] = useState(null);

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {},
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const fromDate = form.watch('fromDate');
  const toDate = form.watch('toDate');

  const currentRange = useMemo(() => {
    return `${moment(fromDate).format('L')} - ${moment(toDate).format('L')}`;
  }, [fromDate, toDate]);

  const formStateCode = form.watch('stateCode');

  const exportToPdf = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      setIsLoader(true);
      const data = await dispatch(getClientCourtReportAsPdf(lastReportId)).unwrap();
      openOrDownloadBinary({
        content: data.content,
        fileName: data.originalFileName,
        contentType: data.contentType,
      });
    } catch (error) {
      notificationUtils.error(error.message || 'Something went wrong');
    } finally {
      setIsLoader(false);
    }
  };

  const handleSubmit = async (values) => {
    const data = {
      stateCode: formStateCode.value,
      fromDate: values.fromDate,
      toDate: values.toDate,
    };

    setIsLoader(true);

    try {
      const { reportId } = await dispatch(createClientCourtReport(data)).unwrap();
      setLastReportId(reportId);
      let done = false;

      while (!done) {
        const {
          items,
          reportStatus: { code, message },
        } = await dispatch(getClientCourtReport(reportId)).unwrap();

        if (code === 'Completed') {
          setTableData(items);
          setCurrentState(formStateCode.value);
          done = true;
        } else if (code === 'Error') {
          notificationUtils.error(message);
          done = true;
        } else {
          await timeoutPromise(5000);
        }
      }
    } catch (e) {
      notificationUtils.error(e.message || 'Something went wrong');
    }
    setIsLoader(false);
  };

  const cutOpposingParty = (opposingParty = '') => {
    const opposingPartyValues = opposingParty.split(',');
    if (opposingPartyValues.length > 1) return `${opposingPartyValues[0]} Et Al.`;

    return opposingParty;
  };

  return (
    <Stack direction="column" mb={6}>
      <ShadowBlock>
        {isLoader && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100vw"
            height="100vh"
            position="absolute"
            top="0"
            left="0"
            zIndex={10}
          >
            <CircularProgress />
          </Box>
        )}
        <Form form={form} onSubmit={handleSubmit}>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={3}
            rowGap={2}
            flexWrap="wrap"
            py={1}
          >
            {CourtReportFormBoxes.map((i) =>
              i.inputBoxes.map((item) => {
                if (item.isDatePicker) {
                  return (
                    <Field
                      name={item.name}
                      key={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange, error }) => (
                        <DateOrTimePicker
                          name={item.name}
                          label={item.label}
                          isDate
                          error={error}
                          Svg={Calender}
                          selected={field.value}
                          isMandatory={item.isMandatory}
                          width={item.width}
                          height={item.height}
                          placeholder={item.placeholder}
                          setSelectedTimeOrDate={onCustomChange(field.onChange)}
                        />
                      )}
                    />
                  );
                }

                if (item.isDropdown) {
                  return (
                    <Field
                      key={item.name}
                      name={item.name}
                      isMandatory={item.isMandatory}
                      render={({ field, onCustomChange }) => (
                        <Dropdown
                          value={field.value}
                          isAddDisabled={false}
                          isSearchable
                          label={item.label}
                          width={item.width}
                          isColumn
                          placeholder={item.placeholder}
                          options={userStates}
                          onChange={onCustomChange(field.onChange)}
                          isMandatory={item.isMandatory}
                        />
                      )}
                    />
                  );
                }

                return null;
              }),
            )}
          </Stack>
          <Stack direction="row" mt={3} gap={2}>
            <MButton
              type="submit"
              size="medium"
              disabled={isLoader || !form.formState.isValid}
              data-testid="run_report_btn"
            >
              Run Report
            </MButton>
          </Stack>
        </Form>
      </ShadowBlock>

      {tableData ? (
        <Box mt={4}>
          <ShadowBlock customStyles={{ gap: 0 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
              <Typography variant="h5" fontWeight={600} mr={2} data-testid="report_title">
                Court Report for {stateNames[currentState]} {currentRange}
              </Typography>
              <MButton
                variant="bordered"
                type="button"
                onClick={exportToPdf}
                size="medium"
                disabled={
                  isLoader || !form.formState.isValid || !lastReportId || !tableData?.length
                }
                startIcon={<PdfIcon />}
              >
                Export PDF
              </MButton>
            </Box>
            {tableData?.length ? (
              tableData.map((data, i) => {
                return (
                  <StyledContainer maxWidth={false} disableGutters>
                    <StyledAccordion defaultExpanded={false}>
                      <AccordionSummary expandIcon={<ArrowDown />}>
                        <Typography variant="bodyL600">{data.countyName}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {data.courtDateTimes.map((cdt) => (
                          <Stack direction="column" mb={2}>
                            <Stack direction="row" gap={1} mb={1.5}>
                              <Typography variant="bodyM400" fontWeight={600}>
                                {moment(cdt.courtDateTime).format('MM/DD/YY')}
                              </Typography>
                              <Typography variant="bodyM400" fontWeight={600}>
                                {moment(cdt.courtDateTime).format('hh:mm A')}
                              </Typography>
                            </Stack>
                            {cdt.courts.map((court) => (
                              <Stack
                                direction="column"
                                p={3}
                                boxShadow="0px 3px 20px 0px rgba(69, 80, 121, 0.13)"
                              >
                                <Typography mb={1} variant="bodyL600" fontWeight={600}>
                                  {court.courtName}
                                </Typography>
                                <Table
                                  columns={CourtReportColumns}
                                  rows={court?.cases?.map((caseValue) => ({
                                    ...caseValue,
                                    opposingParty: cutOpposingParty(caseValue?.opposingParty),
                                  }))}
                                />
                              </Stack>
                            ))}
                          </Stack>
                        ))}
                        {i < tableData.length - 1 && (
                          <Box
                            height="1px"
                            backgroundColor={palette.additional.lines}
                            width="100%"
                            my={5.5}
                          />
                        )}
                      </AccordionDetails>
                    </StyledAccordion>
                  </StyledContainer>
                );
              })
            ) : (
              <Box
                display="flex"
                width="1140px"
                flexDirection="column"
                alignItems="center"
                mt={12}
                mb={25}
                mx="auto"
              >
                <EmptyBlock
                  title="No Records"
                  desc="Please change filters to display report"
                  descFontSize={18}
                />
              </Box>
            )}
          </ShadowBlock>
        </Box>
      ) : null}
    </Stack>
  );
};

export default CourtReport;
