import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { formatUSD } from '../../../../../../utils/formatCurrency';

const SoughtAmountCell = ({ value, row }) => {
  const totalSoughtAmount = Number(row?.original?.totalSoughtAmount) || 0;

  if (row?.original?.isTotalRow) {
    return (
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 700,
          textAlign: 'right',
          width: '100%',
        }}
      >
        {formatUSD(totalSoughtAmount)}
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        fontSize: '16px',
        fontWeight: 500,
        textAlign: 'right',
        width: '100%',
      }}
    >
      {value ? formatUSD(value) : null}
    </Typography>
  );
};

SoughtAmountCell.propTypes = {
  value: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.shape({
      totalSoughtAmount: PropTypes.number,
      isTotalRow: PropTypes.bool,
    }),
  }).isRequired,
};

SoughtAmountCell.defaultProps = {
  value: '',
};

export default SoughtAmountCell;
